
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import StatusesList from '@/components/pages/shared/StatusesList.vue'
import EmptyState from '@/components/shared/templates/EmptyState.vue'
import { chatStatusOptions } from '@/definitions/_general/_data/optionsList'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmButton,
    WhiteBlock,
    DetailsHero,
    StatusesList,
    ChatsSettingsPage,
    EmptyState,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Inbox', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Chat statuses' },
    ]
    const statuses = ref([
      ...chatStatusOptions.map((status: any) => ({
        ...status,
        isDefault: true,
      })),
      {
        name: 'pending',
        color: 'blue',
        outline: true,
      },
    ])

    return {
      isEmptyMode,
      breadcrumbs,
      statuses,
    }
  },
})
